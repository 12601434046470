

























































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import QRCode from "qrcode";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import copy from 'copy-to-clipboard';

import { uploadFile } from "@/models/_request";
import AusterButton from "@/components/AusterButton.vue";
import AusterInput from "@/components/AusterInput.vue";
import AusterInputModal from "@/components/AusterInputModal.vue";

import appointmentModel from "@/models/appointment.model";
import appointmentStatusModel from "@/models/appointment-status.model"

@Component({
  components: {
    ClipLoader,
    AusterButton,
    AusterInput,
    AusterInputModal,
  },
})
export default class AppointmentDetails extends Vue {
  appointmentId: number = null;

  appointment: any = null;

  qrCodeDataUrl: string = "";

  voucherCode: string = "";

  cancelModalActive: boolean = false;

  appointmentStatus: string = "";

  appointmentType: string = "";
  showInfoPopup: boolean = false;

  patientCancellationWarning = "Tem certeza que deseja cancelar a sua consulta?";
  psychologistCancellationWarning = "Tem certeza que deseja cancelar essa consulta?";

  //campos de status
  statusOptions = [];
  reasonsOptions = [];
  priorityOptions = [];  

  async mounted() {
    this.appointmentId = parseInt(this.$route.params.id);
    this.loadAppointment()
      .catch(error => console.error(error));
    //para status    
    const statusAll = await appointmentStatusModel.search({ });
    //console.log(statusAll);
    this.statusOptions = statusAll.filter(s => s.statusType === 'STATUS');
    this.reasonsOptions = statusAll.filter(s => s.statusType === 'REASON');
    this.priorityOptions = statusAll.filter(s => s.statusType === 'PRIORITY');    
  }

	@Watch("$route")
	updateRoute(route: any) {
    this.appointmentId = parseInt(route.params.id);
    this.loadAppointment()
      .catch(error => console.error(error));
  }

  async loadAppointment() {
    try {
      this.appointment = await appointmentModel.getDetails(this.appointmentId);
      console.log('Appointment', this.appointment);
      if (this.appointment && !this.appointment.kind) {
        this.appointment.kind = "SINGLE";
      }

      this.appointmentStatus = await appointmentModel.translateSelectValue("status", this.appointment.status);
      if (this.appointment?.pixString) {
        this.qrCodeDataUrl = await QRCode.toDataURL(this.appointment?.pixString);
      }
      this.appointment.startDateTime = this.appointment.startDateTime.replace('Z','');
    } catch (error) {
      this.$vs.notification({ text: "Erro ao carregar consulta. Verifique sua conexão com a internet.", color: "#FF6767" });
    }
  }

  get appointmentDuration() {        
    if (this.appointment?.kind === "SINGLE") {
      //return "30";
      return "45";
    } else if (this.appointment?.kind === "DOUBLE") {
      return "50";
    }
    return "";
  }

  openPsychologist() {
    if (this.userRole === "PATIENT") {
      this.$router.push(`/dash/psicologo/${this.appointment.psychologistUser?.id}`);
    }
  }

  async openReceipt() {
    window.open(this.appointment.receiptUrl, '_blank');
  }

  copyPixString() {
    copy(this.appointment?.pixString);
    this.$vs.notification({ text: "Código copiado", color: "#2E7179" });
  }

  pickReceipt() {
    (this.$refs.fileInput as any).click();
  }

  async uploadReceipt(fileList: FileList) {
    const loading = this.$vs.loading({ color: '#FABA01' });
    try {
      const file = await uploadFile(fileList[0]);
      await appointmentModel.sendReceipt(this.appointment.id, file.uuid);
      this.$vs.notification({ text: "O comprovante foi enviado e está sendo analisado.", color: "#2E7179" });
      await this.loadAppointment();
    } catch(error) {
      this.$vs.notification({ text: "Não foi possível enviar. Tente novamente.", color: "#FF6767" });
      console.error(error);
    }
    loading.close();
  }

  @Watch("voucherCode")
  transformVoucherCodeToUpperCase(voucherCode, previousVoucherCode) {
    if (voucherCode !== previousVoucherCode) {
      this.voucherCode = voucherCode.toUpperCase().slice(0, 10);
    }
  }

  async applyVoucher() {
    //validações
    if (!this.voucherCode) {
      this.$vs.notification({ text: 'Informar o voucher', color: "#FF6767" });
      return;
    }

    if (!this.appointmentType) {
      this.$vs.notification({ text: 'Selecionar uma opção de chamada.', color: "#FF6767" });
      return;
    }

    //teste
    //this.$vs.notification({ text: "Voucher aplicado com sucesso", color: "#2E7179" });    
    //this.showInfoPopup = true;    
    
    const loading = this.$vs.loading({ color: '#FABA01' });    
    try {
      await appointmentModel.scheduleVoucher(this.appointment.id, 'SINGLE', this.voucherCode, this.appointmentType);
      this.$vs.notification({ text: "Voucher aplicado com sucesso", color: "#2E7179" });
      if (this.appointmentType !== "FIQUEOK") {
        //exibir o pop-up do telefone
        this.showInfoPopup = true;
      }
      await this.loadAppointment();
    } catch(error) {
      console.error(error);
      let errorMessage = error?.message ?? "Voucher inválido";
      if (error.message === "API_NOT_FOUND") {
        errorMessage = "Voucher não encontrado";
      } else if (error.message === "API_FORBIDDEN") {
        errorMessage = "Voucher esgotado";
      }
      this.$vs.notification({ text: errorMessage, color: "#FF6767" });
    }
    loading.close();    
    
  }

  async saveStatus() {
    //console.log(this.statusStatus, this.statusReason, this.statusPriotity);
    if (this.appointment.status === 'COMPLETED' && !this.appointment.medicalRecord) {
      this.$vs.notification({ text: 'Informar o prontuário do paciente', color: "#FF6767" });
      return;
    }
    if (!this.appointment.statusStatus || !this.appointment.statusReason || !this.appointment.statusPriority) {
      this.$vs.notification({ text: 'Informar todos os status', color: "#FF6767" });
      return;
    }    
    
    const loading = this.$vs.loading({ color: '#FABA01' });    
    try {
      await appointmentModel.saveStatus(this.appointment.id, this.appointment.statusStatus, this.appointment.statusReason, this.appointment.statusPriority,
      this.appointment.medicalRecord);
      this.$vs.notification({ text: "Atualizado com sucesso", color: "#2E7179" });
      await this.loadAppointment();
    } catch(error) {
      console.error(error);
      const errorMessage =  error?.message ?? "Não foi possível atualizar a consulta ";      
      this.$vs.notification({ text: errorMessage, color: "#FF6767" });
    }
    loading.close();    
    
  }

  async requestCancel() {
    //aplicar regra de 24 horas   
    this.cancelModalActive = true;
  }

  async cancelAppointment(notes: string) {
    const loading = this.$vs.loading({ color: '#FABA01' });
    try {
      await appointmentModel.cancel(this.appointment.id, notes);
      this.$vs.notification({ text: "A consulta foi cancelada", color: "#2E7179" });
      this.$router.go(-1);
    } catch(error) {
      console.error('erro api', error);
      if (error.message) {
        this.$vs.notification({ text: error.message, color: "#FF6767" });
      } else {
        this.$vs.notification({ text: "Não foi possível cancelar.", color: "#FF6767" });
      }
    }
    loading.close();
  }

  openVideoRoom() {
    this.$router.push(`/dash/sala/${this.appointmentId}`)
  }

  get userRole() {
    return this.$store.state.activeUser?.role;
  }
}
